import SEO from "src/components/layout/SEO";
import Logo from "src/components/Logo";
import useTenant from "src/hooks/api/services/tenants/useTenant";

export default function MaintenanceView() {
	const { tenant } = useTenant();
	return (
		<>
			<SEO title={`Momenteel heeft ${tenant.code} onderhoud`} />
			<main className="flex flex-col flex-1 bg-dark text-white">
				<div className="flex flex-col flex-1 container">
					<nav className="flex justify-center items-center py-4">
						<Logo color="white" className="w-[240px] block" />
					</nav>
					<div className="flex flex-col flex-1 mx-auto justify-center text-center">
						<h1 className="mb-4">
							We zijn de ervaringen aan het verbeteren
						</h1>
						<p className="opacity-60 max-w-md mx-auto">
							Onze developers zijn druk bezig met het uitrollen
							van een nieuwe versie van {tenant.name}. We zijn
							z.s.m. weer online!
						</p>
					</div>
					<footer>
						<div className="flex gap-2 opacity-60 justify-between items-center py-4">
							<a href={`mailto: support@${tenant?.urls?.domain}`}>
								support@{tenant?.urls?.domain}
							</a>
						</div>
					</footer>
				</div>
			</main>
		</>
	);
}
